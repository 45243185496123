import ImgEmptyProfile from './assets/images/empty_profile.png';
import TreeChart from 'd3-org-chart';

const generatePersonTemplate = (person) => {
  return `<div style="display: flex; align-items: center; margin-top: 16px;">
    <img src="${person.image_url || ImgEmptyProfile}" alt="profile" style="width: 100px; height: 100px; border-radius: 50px"/>
    <div style="margin-left: 8px; flex-grow: 1;">
      <div style="font-size: 24px; font-weight: bold; text-align: center;">${person.name}</div>
      <div style="font-size: 20px; text-align: center;">${person.role}</div>
    </div>
  </div>`;
};

const generatePeopleTemplate = (people) => {
  let result = '';
  people.forEach((person) => result += generatePersonTemplate(person));
  return result;
};

const renderItem = (data) => {
  const height = (data.name ? 100 : 28) + 125 * data.people.length;
  const titleTemplate = data.name ? `<div style="font-size: 30px; font-weight: bold; text-align: center">${data.name}</div>` : '';

  return {
    nodeId: data.id,
    parentNodeId: data.parent_id,
    width: 350,
    height: height,
    borderWidth: 1,
    borderRadius: 0,
    borderColor: {
      red: 15,
      green: 140,
      blue: 121,
      alpha: 1
    },
    backgroundColor: {
      red:247,
      green:247,
      blue:249
    },
    // nodeImage: {
    //   url: ImgEmptyProfile,
    //   width: profileImageSize,
    //   height: profileImageSize,
    //   centerTopDistance: profileImageSize / 2 + 16,
    //   centerLeftDistance: profileImageSize / 2 + 16,
    //   cornerShape: 'CIRCLE',
    //   shadow: false,
    //   borderWidth:0,
    //   borderColor:{
    //     red:19,
    //     green:123,
    //     blue:128,
    //     alpha:0,
    //   }
    // },
    template: `<div style="padding: 16px; height: ${height-1}px; background-image: linear-gradient(to right , #FFFFFF, #ECEDF0); color: #2A2A2A;">
      ${titleTemplate}
      ${generatePeopleTemplate(data.people)}
    </div>`,
    connectorLineColor: {
      red:59,
      green:60,
      blue:63,
      alpha:1
    },
    connectorLineWidth: 2,
    dashArray: '',
    expanded: true
  };
};

export const initializeOrgChart = () => {
  const orgChartElement = document.getElementById('org-chart');
  if (!orgChartElement) return ;

  $.get('/admin/employees/org_chart_data', (data) => {
    const chartData = data.map((item) => renderItem(item));
    new TreeChart()
      .container('#org-chart')
      .data(chartData)
      .svgWidth(orgChartElement.clientWidth)
      .svgHeight(orgChartElement.clientHeight)
      .initialZoom(0.4)
      .render();
  })
};
